// const PersonCard = () => importGuid("@/views/sysviews/organization/person/PersonCard");
import PersonCard from './PersonCard.vue';
import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, inject} from 'vue';
import PersonListUtil,{IPersonListDataObj} from "@/views/sysviews/organization/person/personListUtil";
import language from './personLanguage'
const PersonListHelper = defineComponent({
    name: "pageList",
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IPersonListDataObj=reactive<IPersonListDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            pageListRef:{} as any,
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.Api.buildUrl('/person/importData')
                },
                downloadParams:{
                    sourceName:'personImportTemplate.xlsx',
                    downLoadFileName:proxy.$t('person.downLoadFileName'),
                    action:utils.Api.buildUrl('/person/downLoadResourceFile')
                }
            },
            corpTreeData: [],
            deptTreeData: [],
            pageList: {
                queryParam: {
                    from:props.engineParams?'others':''
                },
                multiple:props.engineParams?true:false,
                isShowFixCol:props.engineParams?false:true,
                modelComp: PersonCard,
                modelMethod: utils.Api.buildUrl("/person/pageData")
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PersonListUtil(proxy,dataObj)
        })
        onMounted(()=>{
            nextTick(async()=>{
                dataObj.corpTreeData = await utils.Api.corpTreeData();
                await dataObj.utilInst.requestDeptData('',dataObj.utilInst);
            })
        })
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        // const queryHandler=()=>{
        //     proxy.$message(proxy.$t('abc'))
        // }
        return{
            ...toRefs(dataObj),importHandler
        }
    },
    components: {}
});
export default PersonListHelper;